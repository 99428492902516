import { Store, StoreConfig } from '@datorama/akita';
import { ResultsParams } from '../metadata/model';

export interface SearchState {
	results?: ResultsParams;
	paginationPageNumber?: number;
	itemSelectedFromAutocomplete?: any;
	textSearch?: string;
	textSearchValue?: string;
	pageSelectionChosen?: any;
	activeFilters?: any;
	filters?: any;
	listOfFiltersLabel?: any;
	filtersQuery?: any;
}

const initialState: SearchState = {};

@StoreConfig({ name: 'search' })
export class SearchStore extends Store<SearchState> {
	constructor() {
		super(initialState);
	}

	updateSearch(newSearch) {
		this.update((state) => ({
			...state,
			...newSearch,
		}));
	}

	cleanSearch() {
		this.update((state) => initialState);
	}
}
