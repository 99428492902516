import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'secure',
		pathMatch: 'full',
	},
	{
		path: 'secure',
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				redirectTo: 'search',
				pathMatch: 'full',
			},
			{
				path: 'search',
				loadChildren: () => import('./modules/search/search.module').then((m) => m.SearchModule),
			},
			{
				path: 'create',
				loadChildren: () => import('./modules/property-creation/property-creation.module').then((m) => m.PropertyCreationModule),
			},
			{
				path: 'canvas',
				loadChildren: () => import('./modules/canvas/canvas.module').then((m) => m.CanvasModule),
			},
			{
				path: 'canvas-callback',
				loadChildren: () => import('./modules/canvas-callback/canvas-callback.module').then((m) => m.CanvasCallbackModule),
			},
			{
				path: '**',
				redirectTo: 'search',
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
