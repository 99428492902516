export const environment = {
  production: false,
  // googleAPIKey: 'AIzaSyCGLEE4SX2c9gZvZY-h6yDuOydtGlRhEI0',
  // capforceApi: 'https://capforce--uat.sandbox.lightning.force.com',
  // api: 'https://apps.psc-dev.skyline-be.com/api',
  googleAPIKey: 'AIzaSyC00mln5I7cgxmR-yCKYuDqvn40ZjsoWRI',
  capforceApi: 'sp4q1@https://capforce--sp4q1.sandbox.lightning.force.com||spminorq1@https://capforce--spminorq1.sandbox.lightning.force.com||spmajor@https://capforce--spmajor.sandbox.lightning.force.com',
  subscriptionApiManagementKey: '359cabbc6f544dd68dbe649a3a2bb905',  
  apiManagementEndPoint: '/api-dev',
  api: '/api-dev',
  apiCanvas:'/api-canvas-dev',
  useEdpApi: 'y',
  useApim: 'n',
  env: 'dev',
  datadogClientToken: 'pub3b4a72ea02389de220fcf738b99383c0'
};
