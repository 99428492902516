import { Store, StoreConfig } from '@datorama/akita';
import { PropertyItem } from '../metadata/model';

const initialState: PropertyItem = {};

@StoreConfig({ name: 'property' })
export class PropertyStore extends Store<PropertyItem> {
	constructor() {
		super(initialState);
	}

	updateProperty(newProperty) {
		this.update((state) => Object.assign(newProperty, state));
	}

	removeProperty() {
		this.update((state) => ({}));
	}
}
