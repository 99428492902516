import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { SkyzonModule } from '@skyline-ai/skyzon-angular';
import { NgxMaskModule } from 'ngx-mask';
import { HttpCanvasAuthInterceptor } from './interceptors/http-canvas-auth.interceptor';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '../environments/environment';
import { PropertyStore } from './stores/property.store';
import { SearchStore } from './stores/search.store';
import {AuthService} from './auth.service'
@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		FormsModule,
		SkyzonModule,
		NgxMaskModule.forRoot(),
		environment.production ? [] : AkitaNgDevtools.forRoot(),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpCanvasAuthInterceptor,
			multi: true,
		},
		PropertyStore,
		SearchStore,
		AuthService,
	],
	exports: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
