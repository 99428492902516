import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import mixpanel from 'mixpanel-browser';

export const isLocalDevelopment = window?.location?.hostname === 'localhost';

if(!isLocalDevelopment) {
	// Mixpanel init
	mixpanel.init('fac28f65dee3341d6b4900d801f9ea8d', {
		batch_requests: true,
		api_host: 'https://api.mixpanel.com',
	});
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
