import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable()
export class HttpCanvasAuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Get from auth service

    const authTokenCanvas = this.authService.getAuthInfo();

    // Attach the token to the headers
    const authReq = req.clone({
      headers: req.headers.set('AuthorizationCanvas', authTokenCanvas.secureId),
    });
    console.log('authReq', authReq);
    // Send the request
    return next.handle(authReq);
  }
}
