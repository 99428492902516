import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authInfo: any;
  canvasData: any;
  
  public async isTokenSavedInAuthService(): Promise<boolean> {
    const authTokenCanvas = this.authInfo;
    return !!authTokenCanvas?.secureId;
  }

  public async saveCanvasToken(secureId: string): Promise<boolean> {
    if (!secureId) {
      return false;
    }
    try {
      console.log('secureId received in local', secureId);
      const split = secureId.split('.', 2);
      const encodedEnvelope = split[1];

      // Deserialize the json body
      const json_envelope = window.atob(encodedEnvelope);
      console.log('json_envelope received in local', json_envelope);

      let email = '';
      let opportunityId = '';
      try {
        const canvasData = JSON.parse(json_envelope);
        this.canvasData = canvasData;
        console.log('canvasData', canvasData);

        if (canvasData?.context?.user) {
          email = canvasData.context.user.email;
        }
        if (canvasData?.context?.environment?.parameters) {
          opportunityId = canvasData.context.environment.parameters.opportunityId;
        }
      } catch (e) {
        throw 'Error deserializing JSON: ' + e;
      }

      return await this.saveInAuthService(secureId, email, opportunityId);
    } catch (error) {
      console.error('Auth error: ', error);
      return false;
    }
  }

  public async saveInAuthService(secureId: string, email: string, opportunityId: string): Promise<boolean> {
    const localInfo = {
      secureId: secureId,
      email: email,
      opportunityId: opportunityId,
    };
    console.log('localInfo', localInfo);

    this.authInfo = localInfo;

    return true;
  }

  getClient() {
    return this.canvasData?.client;
  }

  getAuthInfo() {
    return this.authInfo;
  }

  getUserId() {
    return this.authInfo.email;
  }
}
