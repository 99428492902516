import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    console.log('route.queryParams[secureId]', route.queryParams['secureId']);

    if ((await this.authService.isTokenSavedInAuthService()) && !route.queryParams['secureId']) {
      return true;
    }
    const secureId = decodeURIComponent(route.queryParams['secureId']);
    if (secureId && secureId != 'undefined') {
      return this.authService.saveCanvasToken(secureId);
    }
    console.log('route.queryParams', route.queryParams);
    console.log('secureId', secureId);
    return false;
  }
}
