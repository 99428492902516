import { Component } from '@angular/core';

@Component({
	selector: 'psc-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'Search Property';

	closeIframe(): void {
		// todo
	}
}
